import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Rewrite`}</h1>
    <h4>{`June 24–30 2018 | Vinalhaven, ME and August 6–10 2018 | Devon, BR`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAG2S9cOiCj/xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIRIQMx/9oACAEBAAEFAumQXrZLSoos/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAECAx/9oACAEBAAY/Akg//8QAGRABAQEBAQEAAAAAAAAAAAAAAQARITFx/9oACAEBAAE/IT9VtYbpE5fJBHTf/9oADAMBAAIAAwAAABCY7//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EEf/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QhX//xAAYEAEBAQEBAAAAAAAAAAAAAAABEQAxYf/aAAgBAQABPxAw7BJpMtuIekjQ08xTqZ1Xf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Rewrite",
          "title": "Rewrite",
          "src": "/static/839eb82ed6cb88f5c90651424100b604/e5166/__IMG_2417.jpg",
          "srcSet": ["/static/839eb82ed6cb88f5c90651424100b604/f93b5/__IMG_2417.jpg 300w", "/static/839eb82ed6cb88f5c90651424100b604/b4294/__IMG_2417.jpg 600w", "/static/839eb82ed6cb88f5c90651424100b604/e5166/__IMG_2417.jpg 1200w", "/static/839eb82ed6cb88f5c90651424100b604/d9c39/__IMG_2417.jpg 1800w", "/static/839eb82ed6cb88f5c90651424100b604/df51d/__IMG_2417.jpg 2400w", "/static/839eb82ed6cb88f5c90651424100b604/768c6/__IMG_2417.jpg 3264w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://rewrite.designinquiry.net" title="Rewrite Website" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`In the 21st Century, nothing is created from scratch. When we design or write something, we begin with what is already there, the thing that needs amplifying, editing, making user friendly,  massaging into a message, and the circumstances into which the designed thing will be introduced. We may not be conscious of the long trail of precedents, everything like it that has already been done, but our thinking and design choices are nevertheless informed by all the books, buildings, butter dishes or bezier curves that have preceded the books, buildings, butter dishes and bezier curves we are currently designing. “To design is always to redesign” (Bruno Latour 2008)`}</strong></p>
    <p><strong parentName="p">{`This is not to imply that to rewrite or redesign is a stifling, constrained task. On the contrary, to weave the strands of influence into a startling new form is the very essence of creativity, as any dancer or jazz musician will know. And to reweave, redesign or rewrite one’s own work is to revisit it, sometimes years later, as a different person, energised or embarrassed enough by it to want to take it somewhere new, to demonstrate to ourselves and the world that we have actually learned something. It’s a small step from here to the idea of picking up someone else’s work and finishing it for them — or re-writing it. A rewrite based on a misreading, to paraphrase Harold Bloom.`}</strong></p>
    <p><strong parentName="p">{`DesignInquiry invites thinkers and makers from all disciplines to live and work in a REWRITE residency at one or both of its sites for 2018. We welcome applications for projects that can substantially advance during the one week period of the residency. Your proposed project may be: a creative project previously shelved that deserves a re-visit; a collaborative project that will benefit from an exchange; a project initiated at a previous DesignInquiry that can be further developed or taken in a new direction.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      